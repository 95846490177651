import React, { useEffect, useState } from 'react';
import './WhyUs.css';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import animationData2 from '../../assets/overview.png'; // PNG file
import animationData3 from '../../assets/admin-alt.png'; // PNG
import animationData4 from '../../assets/dollar.png'; // Lottie JSON
import WhyUs_Data_En from '../../assets/why_us_en';
import WhyUs_Data_Lv from '../../assets/why_us_lv';

const WhyUs = () => {
  const { i18n, t } = useTranslation();
  const [whyUsData, setWhyUsData] = useState([]);

  const animations = {
    animation2: animationData2, // This is the PNG
    animation3: animationData3, // This is a Lottie animation
    animation4: animationData4, // Another Lottie animation
  };

  useEffect(() => {
    if (i18n.language === 'lv') {
      setWhyUsData(WhyUs_Data_Lv);
    } else {
      setWhyUsData(WhyUs_Data_En);
    }
  }, [i18n.language]);

  return (
    <div id="whyus" className="whyus">
      <h1 className="whyus-title">{t('whyus')}</h1>
      <div className="whyus-content">
        {whyUsData.map((section, index) => (
          <div key={index} className="whyus-section">
            {/* Conditional Rendering: Check if it's a PNG or Lottie animation */}
            {section.animation === 'animation2' || section.animation === 'animation3' || section.animation === 'animation4'? (
              <img src={animations[section.animation]} alt={section.title} className="whyus-image" />
            ) : (
              <Lottie animationData={animations[section.animation]} className="whyus-animation" />
            )}
            <h2>{section.title}</h2>
            <p>{section.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyUs;

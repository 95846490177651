import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react'; // Import TinyMCE
import './AdminPage.css';  // Link the CSS file

const AdminPage = () => {
  // Blog states
  const [blogs, setBlogs] = useState([]);
  const [blogFormData, setBlogFormData] = useState({
    title: '',
    description: '',
    content: '', // Store rich text content here
    tags: ''
  });
  const [isBlogEditing, setIsBlogEditing] = useState(false);
  const [blogEditId, setBlogEditId] = useState(null);

  // Service states
  const [services, setServices] = useState([]);
  const [serviceFormData, setServiceFormData] = useState({
    s_name: '',
    s_desc: '',
    s_full_desc: '',
    language: 'en'  // Include default language
  });
  const [isServiceEditing, setIsServiceEditing] = useState(false);
  const [serviceEditId, setServiceEditId] = useState(null);

  useEffect(() => {
    fetchBlogs();
    fetchServices();
  }, []);

  // Fetch blogs
  const fetchBlogs = async () => {
    try {
      const res = await axios.get('https://agdata.lv/api/blogs');
      setBlogs(res.data.blogs);
    } catch (err) {
      console.error('Error fetching blogs:', err);
    }
  };

// Fetch services 
const fetchServices = async () => {
  try {
    const res = await axios.get('https://agdata.lv/api/services/all'); // Fetch all services
    setServices(res.data);  // Set services data without filtering by language
  } catch (err) {
    console.error('Error fetching services:', err);
  }
};


  // Blog form input change handler
  const handleBlogInputChange = (e) => {
    const { name, value } = e.target;
    setBlogFormData({ ...blogFormData, [name]: value });
  };

  // Service form input change handler
  const handleServiceInputChange = (e) => {
    const { name, value } = e.target;
    setServiceFormData({ ...serviceFormData, [name]: value });
  };

  // Blog Editor change handler
    const handleBlogEditorChange = (content) => {
    setBlogFormData({ ...blogFormData, content });
  };

  // Submit blog form
  const handleBlogSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isBlogEditing) {
        await axios.put(`https://agdata.lv/api/blogs/${blogEditId}`, blogFormData);
        setIsBlogEditing(false);
        setBlogEditId(null);
      } else {
        await axios.post('https://agdata.lv/api/blogs', blogFormData);
      }
      setBlogFormData({ title: '', description: '', content: '', tags: '' });
      fetchBlogs();
    } catch (err) {
      console.error('Error saving blog:', err);
    }
  };

  // Submit service form
  const handleServiceSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isServiceEditing) {
        await axios.put(`https://agdata.lv/api/services/${serviceEditId}`, serviceFormData);
        setIsServiceEditing(false);
        setServiceEditId(null);
      } else {
        await axios.post('https://agdata.lv/api/services', serviceFormData);
      }
      setServiceFormData({ s_name: '', s_desc: '', s_full_desc: '', language: 'en' });
      fetchServices();
    } catch (err) {
      console.error('Error saving service:', err);
    }
  };

  // Delete a blog
  const handleDeleteBlog = async (id) => {
    if (window.confirm('Are you sure you want to delete this blog post?')) {
      try {
        await axios.delete(`https://agdata.lv/api/blogs/${id}`);
        fetchBlogs();
      } catch (err) {
        console.error('Error deleting blog:', err);
      }
    }
  };

  // Delete a service
  const handleDeleteService = async (id) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      try {
        await axios.delete(`https://agdata.lv/api/services/${id}`);
        fetchServices();
      } catch (err) {
        console.error('Error deleting service:', err);
      }
    }
  };

  // Edit a blogREACT_APP_API_URL=https://agdata.lv/api
  const handleEditBlog = (blog) => {
    setBlogFormData({
      title: blog.title,
      description: blog.description,
      content: blog.content,
      tags: blog.tags
    });
    setIsBlogEditing(true);
    setBlogEditId(blog.id);
  };

  // Edit a service
  const handleEditService = (service) => {
    setServiceFormData({
      s_name: service.s_name,
      s_desc: service.s_desc,
      s_full_desc: service.s_full_desc,
      language: service.language // Make sure to include language field here
    });
    setIsServiceEditing(true);
    setServiceEditId(service.id);
  };

  const handleLogout = async () => {
    await axios.post('https://agdata.lv/api/auth/logout', {}, { withCredentials: true });
    window.location.href = '/l1018jsu949';
  };

  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>
      <button className="logout-btn" onClick={handleLogout}>Logout</button>

      {/* Blog Form */}
      <div className="blog-form">
        <h3>{isBlogEditing ? 'Edit Blog' : 'Add New Blog'}</h3>
        <form onSubmit={handleBlogSubmit}>
          <label>Title</label>
          <input
            type="text"
            name="title"
            placeholder="Blog Title"
            value={blogFormData.title}
            onChange={handleBlogInputChange}
            required
          />
          <label>Description</label>
          <textarea
            name="description"
            placeholder="Blog Description"
            value={blogFormData.description}
            onChange={handleBlogInputChange}
            required
          />
          <label>Content</label>
          <Editor
            apiKey="emnhyjssxnhnljtfrb47qztvgd1sophppr3z5tna0wrob90y"
            value={blogFormData.content}
            init={{
              height: 500,
              menubar: false,
              plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount', 'image'],
              toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image | code',
              automatic_uploads: true,
              images_upload_url: 'https://agdata.lv/upload',
              file_picker_types: 'image',
              file_picker_callback: (cb, value, meta) => {
                let input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.onchange = () => {
                  let file = input.files[0];
                  let reader = new FileReader();
                  reader.onload = () => {
                    let id = 'blobid' + (new Date()).getTime();
                    let blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                    let base64 = reader.result.split(',')[1];
                    let blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);
                    cb(blobInfo.blobUri(), { title: file.name });
                  };
                  reader.readAsDataURL(file);
                };
                input.click();
              },
            }}
            onEditorChange={handleBlogEditorChange}
          />
          <label>Tags</label>
          <input
            type="text"
            name="tags"
            placeholder="Tags (comma-separated)"
            value={blogFormData.tags}
            onChange={handleBlogInputChange}
          />
          <div className="form-actions">
            <button className="submit-btn" type="submit">{isBlogEditing ? 'Update Blog' : 'Add Blog'}</button>
            {isBlogEditing && (
              <button className="reset-btn" type="button" onClick={() => setIsBlogEditing(false)}>
                Cancel Edit
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Service Form */}
      {/* Service Form */}
<div className="service-form">
  <h3>{isServiceEditing ? 'Edit Service' : 'Add New Service'}</h3>
  <form onSubmit={handleServiceSubmit}>
    <label>Service Name</label>
    <input
      type="text"
      name="s_name"
      placeholder="Service Name"
      value={serviceFormData.s_name}
      onChange={handleServiceInputChange}
      required
    />
    <label>Service Description</label>
    <Editor
      apiKey="emnhyjssxnhnljtfrb47qztvgd1sophppr3z5tna0wrob90y"
      value={serviceFormData.s_desc}
      init={{
        height: 300,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
          'image'
        ],
        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image | code',
        automatic_uploads: true,
        images_upload_url: 'https://agdata.lv/upload',
        file_picker_types: 'image',
        file_picker_callback: (cb, value, meta) => {
          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.onchange = () => {
            let file = input.files[0];
            let reader = new FileReader();
            reader.onload = () => {
              let id = 'blobid' + (new Date()).getTime();
              let blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
              let base64 = reader.result.split(',')[1];
              let blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };
          input.click();
        }
      }}
      onEditorChange={(content) => setServiceFormData({ ...serviceFormData, s_desc: content })}
    />
    <label>Service Full Description</label>
    <Editor
      apiKey="emnhyjssxnhnljtfrb47qztvgd1sophppr3z5tna0wrob90y"
      value={serviceFormData.s_full_desc}
      init={{
        height: 300,
        menubar: false,
        plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount', 'image', 'table', 'fontsize', 'tablecellbordercolor'], // Added table plugin here
              toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image | code | table | fontsize | tablecellbordercolor', // Added table buttons
        automatic_uploads: true,
        images_upload_url: 'https://agdata.lv/upload',
        file_picker_types: 'image',
        file_picker_callback: (cb, value, meta) => {
          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.onchange = () => {
            let file = input.files[0];
            let reader = new FileReader();
            reader.onload = () => {
              let id = 'blobid' + (new Date()).getTime();
              let blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
              let base64 = reader.result.split(',')[1];
              let blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };
          input.click();
        }
      }}
      onEditorChange={(content) => setServiceFormData({ ...serviceFormData, s_full_desc: content })}
    />
    <label>Language</label>
    <select
      name="language"
      value={serviceFormData.language}
      onChange={handleServiceInputChange}
      required
    >
      <option value="en">English</option>
      <option value="lv">Latvian</option>
    </select>
    <div className="form-actions">
      <button className="submit-btn" type="submit">{isServiceEditing ? 'Update Service' : 'Add Service'}</button>
      {isServiceEditing && (
        <button className="reset-btn" type="button" onClick={() => setIsServiceEditing(false)}>
          Cancel Edit
        </button>
      )}
    </div>
  </form>
</div>


      {/* Blog List */}
      <div className="blog-list">
        <h3>All Blogs</h3>
        {blogs.length > 0 ? (
          <ul>
            {blogs.map(blog => (
              <li key={blog.id} className="blog-item">
                <div className="blog-item-header">
                  <h4>{blog.title}</h4>
                  <div className="blog-actions">
                    <button className="edit-btn" onClick={() => handleEditBlog(blog)}>Edit</button>
                    <button className="delete-btn" onClick={() => handleDeleteBlog(blog.id)}>Delete</button>
                  </div>
                </div>
                <p>{blog.description}</p>
                <p><strong>Tags:</strong> {blog.tags}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No blogs available</p>
        )}
      </div>

      {/* Service List */}
      {/* Service List */}
<div className="service-list">
  <h3>All Services</h3>
  {services.length > 0 ? (
    <ul>
      {services.map(service => (
        <li key={service.id} className="service-item">
          <div className="service-item-header">
            <h4>{service.s_name} ({service.language})</h4> {/* Show service name and language */}
            <div className="service-actions">
              <button className="edit-btn" onClick={() => handleEditService(service)}>Edit</button>
              <button className="delete-btn" onClick={() => handleDeleteService(service.id)}>Delete</button>
            </div>
          </div>
          <p>{service.s_desc}</p>
        </li>
      ))}
    </ul>
  ) : (
    <p>No services available</p>
  )}
</div>
    </div>
  );
};

export default AdminPage;

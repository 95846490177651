import React from 'react';
import './Hero.css';
import { useTranslation } from 'react-i18next';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ReactTyped } from "react-typed";

function Hero() {
  const { t } = useTranslation();
  return (
    <div className='hero'>
      <div className='text-container'>
        <div className="color"></div>
        <div className="color2"></div>
        <h1><span>{t('heroGreeting')}</span></h1>
        <h2>{t('heroDescription')}</h2>
        <AnchorLink href="#contact" offset="50" className="hero-contact">
          {t('contactUs')}
        </AnchorLink>
      </div>
      <div className="fixed-width">
        <h3 className="typing-text">
          <ReactTyped
            strings={["AGDATA"]}
            typeSpeed={130}
            loop
            startDelay={700}
            backDelay={1500}
            backSpeed={100}
            cursorChar="."
            showCursor={true}
          />
        </h3>
      </div>
    </div>
  );
}

export default Hero;

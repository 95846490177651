import React, { useState } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

library.add(fab);

const Footer = () => {
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

  const openPrivacyPolicy = () => {
    setPrivacyPolicyOpen(true);
  };

  const closePrivacyPolicy = () => {
    setPrivacyPolicyOpen(false);
  };

  return (
    <div className='footer'>
      <hr />
      <div className="footer-bottom">
        <p className="footer-bottom-left">ⓒ 2024 AGDATA. All rights reserved.</p>
        <div className="footer-bottom-right">
          <p onClick={openPrivacyPolicy} className="footer-link">Privacy Policy & Cookies</p>
          <p><Link to="/faq" className="footer-link">FAQs</Link></p> 
          <a href="https://facebook.com/yourprofile" target="_blank" rel="noopener noreferrer" className='Icon'>
            <FontAwesomeIcon icon={['fab', 'facebook']} />
          </a>
          <a href="https://www.linkedin.com/company/sia-ag-data/" target="_blank" rel="noopener noreferrer" className='Icon'>
            <FontAwesomeIcon icon={['fab', 'linkedin']} />
          </a>
          <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer" className='Icon'>
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </a>
          <a href="https://www.instagram.com/agdata_lv/" target="_blank" rel="noopener noreferrer" className='Icon'>
            <FontAwesomeIcon icon={['fab', 'instagram']} />
          </a>
        </div>
      </div>

      {/* Privacy Policy Sliding Panel */}
      <div className={`sliding-panel ${isPrivacyPolicyOpen ? 'show' : ''}`}>
        <button className="close-btn-footer" onClick={closePrivacyPolicy}>X</button>
        <div className="sliding-panel-content">
          <h1>Privacy Policy & Cookies</h1>
          <p>
          <b>Privacy Policy<br/></b>
Effective Date: [01.05.2024]<br/>

<br/>AG Data Solutions (“we,” “our,” “us”) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.agdata.lv and when you use our services related to database management, optimization, and analytics. Please read this policy carefully to understand our practices regarding your information.<br/>

<b><br/>1. Information We Collect<br/></b>
We may collect the following types of information:

Personal Information: When you use our services, we may collect personal information, such as your name, email address, phone number, job title, and company name. This information is collected when you:
Submit a contact form or inquiry
Request a service
Subscribe to newsletters or other communications
Join us for events or webinars
Technical Data: We may collect information about your device, browser, and browsing behavior, including:
IP address
Browser type and version
Time zone setting
Browser plug-in types and versions
Operating system and platform
Other device identifiers
Service Data: Information you provide related to database services, including system configurations, queries, or other diagnostic data necessary for providing our services.<br/>
<b><br/>2. How We Use Your Information<br/></b>
We use the information we collect to:

Provide services: Manage and deliver the services you request, including database management, optimization, and analytics.
Improve our services: Analyze and improve our website and offerings to serve you better.
Communicate: Send you updates, newsletters, and promotional materials regarding our services.
Security: Maintain the security and integrity of our services and systems.
Legal Compliance: Comply with applicable laws, regulations, and legal processes.<br/>
<b><br/>3. How We Share Your Information<br/></b>
We may share your information with third parties under the following circumstances:

Service Providers: We may share information with third-party vendors and service providers to help us manage and deliver our services, such as data hosting, payment processing, or technical support.
Legal Obligations: We may disclose your information if required to do so by law, legal process, or in response to valid requests from public authorities.
Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our business, your information may be transferred as part of that transaction.<br/>
<b><br/>4. Your Data Rights<br/></b>
You have the right to:

Access, correct, or delete the personal data we hold about you.
Object or restrict the processing of your personal information.
Withdraw consent for marketing communications.
Request portability of your personal data in a structured, machine-readable format.
To exercise these rights, please contact us at [Insert contact email].<br/>

<b><br/>5. Data Security<br/></b>
We take data security seriously and use appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no security measures are completely foolproof, and we cannot guarantee absolute security.<br/>

<b><br/>6. Retention of Your Data<br/></b>
We retain personal information for as long as necessary to fulfill the purposes described in this Privacy Policy, unless a longer retention period is required by law.<br/>

<b><br/>7. Third-Party Links<br/></b>
Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We recommend reviewing their privacy policies.<br/>

<b><br/>8. Changes to This Privacy Policy<br/></b>
We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page, and the "Effective Date" at the top will be revised.<br/>

<b><br/>9. Contact Us<br/></b>
If you have any questions or concerns about this Privacy Policy, please contact us at:<br/>

AG Data Solutions
Email: info@agdata.lv
Phone: +371 29909930<br/>

<b><br/><br/>Cookies Policy</b><br/>
Effective Date: [01.05.2024]<br/>

<br/>This Cookies Policy explains how AG Data Solutions ("we," "our," "us") uses cookies and similar technologies when you visit our website www.agdata.lv.<br/>

<b><br/>1. What Are Cookies?<br/></b>
Cookies are small text files that are placed on your device (computer, tablet, smartphone) when you visit a website. They are widely used to make websites work efficiently and to provide information to website owners.<br/>

<b><br/>2. How We Use <br/></b>
We use the following types of cookies:

Essential Cookies: These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually set in response to actions you take, such as filling in forms or setting your privacy preferences.

Analytical/Performance Cookies: These cookies allow us to recognize and count the number of visitors and to see how visitors move around our website. This helps us improve the way our website works.

Functionality Cookies: These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we use on our pages.

Targeting Cookies: These cookies may be set through our website by our advertising partners. They may be used to build a profile of your interests and show you relevant adverts on other websites.<br/>

<b><br/>3. Managing Cookies<br/></b>
Most web browsers allow you to control cookies through your browser settings. You can choose to block or delete cookies, but this may affect the functionality of our website.<br/>

To learn more about how to manage cookies in your browser, you can visit:

Google Chrome
Mozilla Firefox
Apple Safari
Microsoft Edge<br/>
<b><br/>4. Third-Party Cookies<br/></b>
Some cookies on our site are set by third parties, such as analytics and advertising networks. These third-party cookies help us to improve the performance of our site and deliver personalized content.<br/>

<b><br/>5. Changes to This Cookies Policy<br/></b>
We may update this Cookies Policy from time to time to reflect changes in our practices. Any updates will be posted on this page with the revised "Effective Date."<br/>

<b><br/>6. Contact Us<br/></b>
If you have any questions about this Cookies Policy, please contact us at:

AG Data Solutions
<br/>
Email: info@agdata.lv<br/>
Phone: +371 25868557<br/><br/><br/>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

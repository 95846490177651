import React, { useState } from 'react';
import './ContactButton.css';
import mailIcon from '../../assets/mail_icon.svg';
import ContactModalForm from '../Modals/ContactModalForm';

const ContactButton = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className="floating-button" onClick={handleClick}>
        <img src={mailIcon} alt="Message" />
      </div>
      {showModal && (
        <div className="modal-background" onClick={handleClick}>
          <div
            className={`modal-content ${showModal ? 'active' : ''}`}
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-button" onClick={handleClick}>
              &times; {/* This is the "X" button */}
            </span>
            <ContactModalForm /> {/* Use the small contact form */}
          </div>
        </div>
      )}
    </>
  );
};

export default ContactButton;

import React, { useState, useEffect } from 'react';
import './Services.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Services = () => {
  const { i18n, t } = useTranslation();
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [isServicePanelOpen, setServicePanelOpen] = useState(false);
  const [contactOverlayOpen, setContactOverlayOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  // Fetch services dynamically from backend based on selected language
  useEffect(() => {
    axios.get(`https://agdata.lv/api/services?language=${i18n.language}`)
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => {
        console.error('Error fetching services:', error);
      });
  }, [i18n.language]);

  const openServicePanel = async (service) => {
  try {
    const response = await axios.get(`https://agdata.lv/api/services/${service.id}`);
    setSelectedService(response.data);  // Assuming the response contains full details
    setServicePanelOpen(true);
  } catch (error) {
    console.error('Error fetching service details:', error);
  }
};

  const closeServicePanel = () => {
    setServicePanelOpen(false);
  };

  const openContactOverlay = () => {
    setContactOverlayOpen(true);
  };

  const closeContactOverlay = () => {
    setContactOverlayOpen(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = t('Name is required');
    }
    if (!formData.email) {
      errors.email = t('Email is required');
    } else if (!validateEmail(formData.email)) {
      errors.email = t('Invalid email format');
    }
    if (!formData.message) {
      errors.message = t('Message is required');
    } else if (formData.message.length > 800) {
      errors.message = t('Message exceeds 800 characters');
    }
    return errors;
  };

  const onSubmit = async (event) => {
  event.preventDefault();
  const errors = validateForm();
  if (Object.keys(errors).length > 0) {
    setFormErrors(errors);
    return;
  }

  try {
    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message,
        access_key: '824b8e53-fabb-4c46-b123-bb5a47f24113'
      })
    });
    const resData = await response.json();
    if (resData.success) {
      alert(resData.message);
      closeContactOverlay();
    } else {
      alert('Error submitting form');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    alert('Form submission failed');
  }
};
const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };



  return (
    <div id='services' className='services'>
      <div className='services-title'>
        <h1>{t('services')}</h1>
      </div>
      <div className="services-container">
        {services.map((service, index) => (
          <div
            key={index}
            className="services-format"
            onClick={() => openServicePanel(service)}
          >
            <div className="service-header">
            
              <h2>{service.s_name}</h2>
              
            </div>
            {/* Render s_desc as HTML */}
            <p dangerouslySetInnerHTML={{ __html: service.s_desc }} />
            
          </div>
        ))}
      </div>

      {/* Service details sliding panel */}
      <div className={`service-details-overlay ${isServicePanelOpen ? 'show' : ''}`}>
        <button className="close-btn" onClick={closeServicePanel}>X</button>
        {selectedService && (
          <div className="service-details">
            <h2>{selectedService.s_name}</h2>
            {/* Render s_full_desc as HTML */}
            <p dangerouslySetInnerHTML={{ __html: selectedService.s_full_desc }} />
            <button className='contact-us-btn' onClick={openContactOverlay}>
              {t('contactUs')}
            </button>
          </div>
        )}
      </div>

      {/* Contact Overlay */}
      {contactOverlayOpen && (
        <div className="contact-overlay">
          <button className="close-btn" onClick={closeContactOverlay}>X</button>
          <div className="contact-form">
            <h2>{t('contactUs')}</h2>
            <form onSubmit={onSubmit}>
              <label>{t('YourName')}</label>
              <input 
                type="text" 
                name="name" 
                placeholder={t('EnterYourName')} 
                value={formData.name}
                onChange={handleInputChange} 
                required 
              />
              {formErrors.name && <p className="error">{formErrors.name}</p>}

              <label>{t('YourEmail')}</label>
              <input 
                type="email" 
                name="email" 
                placeholder={t('EnterYourEmail')} 
                value={formData.email}
                onChange={handleInputChange} 
                required 
              />
              {formErrors.email && <p className="error">{formErrors.email}</p>}

              <label>{t('LeaveTheMessage')}</label>
              <textarea 
                name="message" 
                rows="6" 
                placeholder={t('EnterYourMessage')} 
                value={formData.message}
                onChange={handleInputChange} 
                required
                maxLength="800"
              />
              {formErrors.message && <p className="error">{formErrors.message}</p>}

              <button type="submit">{t('Submit')}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Blog.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Blog = () => {
  const { t } = useTranslation();
  const [blogPosts, setBlogPosts] = useState([]);  // Initialize as an empty array
  const [loading, setLoading] = useState(true);    // Add loading state

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'long',  // 'short' for abbreviated month names like "Jan", "Feb"
      day: 'numeric'
    });
  };

  useEffect(() => {
    // Fetch the latest 3 blogs from the database
    axios.get('https://agdata.lv/api/blogs?limit=3&sort=desc')
      .then((response) => {
        console.log('API Response:', response.data);  // Log API response for debugging
        // Access the 'blogs' key in the response
        const blogs = response.data.blogs;  
        if (Array.isArray(blogs)) {
          setBlogPosts(blogs);  // Set the fetched blogs
        } else {
          setBlogPosts([]);  // Set an empty array if data is not an array
        }
        setLoading(false);  // Set loading to false
      })
      .catch((error) => {
        console.error('Error fetching blogs:', error);
        setBlogPosts([]);  // Set an empty array in case of error
        setLoading(false);  // Set loading to false
      });
  }, []);

  return (
    <div id='blog' className='blog'>
      <div className="blog-title">
        <h1>{t('Blog')}</h1>
      </div>
      <div className="blog-container">
        {loading ? (
          <p>Loading blog posts...</p>
        ) : blogPosts.length > 0 ? (
          blogPosts.map((post) => (
            <div key={post.id} className="blog-post">
              <h2>{post.title}</h2>
              <p>{formatDate(post.date)}</p> {/* Format the date here */}
              <p>{post.description}</p>
              <Link to={`/blog/${post.id}`} className="read-more-btn">{t('ReadMore')}</Link>
            </div>
          ))
        ) : (
          <p>No blog posts available.</p>
        )}
      </div>
      <div className="view-all-blogs">
        <Link to="/blog" className="view-all-btn">{t('ViewAllBlogs')}</Link>
      </div>
    </div>
  );
};

export default Blog;

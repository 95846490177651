import React, { useState } from 'react';
import './Contact.css';
import mail_icon from '../../assets/mail_icon.svg';
import call_icon from '../../assets/call_icon.svg';
import { useTranslation } from 'react-i18next';
import SuccessMessage from '../SuccessMessage'; // Import SuccessMessage

const Contact = () => {
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [showSuccess, setShowSuccess] = useState(false); // State to control success message visibility

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = t('Name is required');
    }
    if (!formData.email) {
      errors.email = t('Email is required');
    } else if (!validateEmail(formData.email)) {
      errors.email = t('Invalid email format');
    }
    if (!formData.message) {
      errors.message = t('Message is required');
    } else if (formData.message.length > 800) {
      errors.message = t('Message exceeds 800 characters');
    }
    return errors;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const formPayload = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
      access_key: '824b8e53-fabb-4c46-b123-bb5a47f24113'
    };

    const res = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(formPayload)
    }).then((res) => res.json());

    if (res.success) {
      setShowSuccess(true); // Show success message
      setTimeout(() => {
        setShowSuccess(false); // Hide success message after 3 seconds
      }, 3000);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  return (
    <div id="contact" className="contact">
      <div className="contact-title">
        <h1>{t('contacts')}</h1>
      </div>
      <div className="contact-section">
        <div className="contact-left">
          <h1>{t('GetInTouch')}</h1>
          <p>{t('WorkingTimes')}</p>
          <p>{t('WorkingDays')}</p>
          <div className="contact-details">
            <div className="contact-detail">
              <img src={mail_icon} alt="" />
              <p>info@agdata.lv</p>
            </div>
            <div className="contact-detail">
              <img src={call_icon} alt="" />
              <p>+371 29909930</p>
            </div>
          </div>
        </div>
        <form onSubmit={onSubmit} className="contact-right">
          <label htmlFor="name">{t('YourName')}</label>
          <input
            type="text"
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleInputChange}
          />
          {formErrors.name && <p className="error">{formErrors.name}</p>}

          <label htmlFor="email">{t('YourEmail')}</label>
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleInputChange}
          />
          {formErrors.email && <p className="error">{formErrors.email}</p>}

          <label htmlFor="message">{t('LeaveTheMessage')}</label>
          <textarea
            name="message"
            rows="8"
            placeholder="Enter your message"
            value={formData.message}
            onChange={handleInputChange}
            maxLength="800"
          />
          {formErrors.message && <p className="error">{formErrors.message}</p>}

          <button type="submit" className="contact-submit">
            {t('Submit')}
          </button>
        </form>
      </div>

      {/* Success message after submission */}
      {showSuccess && <SuccessMessage message={t('Message sent successfully!')} />}
    </div>
  );
};

export default Contact;

import React, { useState, useEffect } from 'react';
import './AllBlogsPage.css';
import { useNavigate, useSearchParams } from 'react-router-dom'; // Add useSearchParams
import axios from 'axios';

const AllBlogsPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [loading, setLoading] = useState(true);
  const [totalBlogs, setTotalBlogs] = useState(0);  // Total blogs count
  const navigate = useNavigate();
  
  const [searchParams, setSearchParams] = useSearchParams();  // To manage URL query params
  const page = Number(searchParams.get('page')) || 1; // Get the current page from query parameter, default to 1

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  // Fetch blogs with pagination and tag filter
  const fetchBlogs = (tag = '', page = 1) => {
    const url = tag 
      ? `https://agdata.lv/api/blogs?tag=${tag}&page=${page}&limit=12`
      : `https://agdata.lv/api/blogs?page=${page}&limit=12`;

    axios.get(url)
      .then((response) => {
        setBlogPosts(response.data.blogs);
        setTotalBlogs(response.data.total);  // Total number of blogs for pagination
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      });
  };

  // Fetch tags
  const fetchTags = () => {
    axios.get('https://agdata.lv/api/tags')
      .then((response) => {
        setTags(response.data);
      })
      .catch((error) => {
        console.error('Error fetching tags:', error);
      });
  };

  useEffect(() => {
    fetchBlogs(selectedTag, page);  // Fetch blogs based on current page and selected tag
    fetchTags();
  }, [page, selectedTag]);  // Refetch when page or selectedTag changes

  // Handle blog click
  const handleBlogClick = (id) => {
    navigate(`/blog/${id}`, { state: { page } });  // Pass the current page in the navigation state
  };

  // Handle tag click
  const handleTagClick = (tag) => {
    setSelectedTag(tag);
    setSearchParams({ page: 1 });  // Reset to page 1 when a tag is selected
  };

  // Handle pagination click
  const handlePageChange = (newPage) => {
    setSearchParams({ page: newPage });  // Update the URL with the new page
  };

  const totalPages = Math.ceil(totalBlogs / 12);  // Calculate total number of pages

  return (
    <div className="all-blogs">
      <h1>All Blogs</h1>

      {/* Tag Filter */}
      <div className="tags-container">
        <button 
          className={`tag ${!selectedTag ? 'active' : ''}`} 
          onClick={() => handleTagClick('')}
        >
          All
        </button>
        {tags.map((tag, index) => (
          <button 
            key={index} 
            className={`tag ${selectedTag === tag ? 'active' : ''}`} 
            onClick={() => handleTagClick(tag)}
          >
            {tag}
          </button>
        ))}
      </div>

      {/* Blog List */}
      {loading ? (
        <p>Loading blogs...</p>
      ) : (
        <div className="all-blog-container">
          {blogPosts.map((post) => {
            const tagList = post.tags.split(',');
            return (
              <div 
                key={post.id} 
                className="all-blog-item" 
                onClick={() => handleBlogClick(post.id)} // Navigate with the current page
              >
                <h2 className="all-blog-title">{post.title}</h2>
                <p>{formatDate(post.date)}</p>
                <p>{post.description}</p>
                <div className="blog-tags">
                  {tagList.slice(0, 2).map((tag, index) => (  // Display only the first 3 tags
                    <span key={index} className="blog-tag">{tag}</span>
                  ))}
                  {tagList.length > 2 && (  // If there are more than 3 tags, show a "+X more"
                    <span className="more-tags">+{tagList.length - 2} more</span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Pagination Controls */}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button 
            key={index + 1} 
            className={`pagination-btn ${page === index + 1 ? 'active' : ''}`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AllBlogsPage;

import React, { useEffect } from 'react';
import './Clients.css';
import { useTranslation } from 'react-i18next';
import LOGO3 from '../../assets/OrangeLogoNB.png';
import LOGO4 from '../../assets/ABTlogo.png';
import LOGO5 from '../../assets/ND.png';
import LOGO6 from '../../assets/GOStack.png';

const Clients = () => {
  const { t } = useTranslation();

  // This effect handles the cloning of .logos-slide and appending it to .logos
  useEffect(() => {
    const logosContainer = document.querySelector('.logos');
    const logoSlide = document.querySelector('.logos-slide');

    if (logoSlide && logosContainer) {
      // Clone the logos-slide and append the cloned version
      const clone = logoSlide.cloneNode(true);
      logosContainer.appendChild(clone);
    }
  }, []); // Empty dependency array ensures this runs only once after component mounts

  return (
    <div id='clients' className='clients'>
      <div className="clients-section">
        <div className="clients-text">
          <h1>{t('ourClients')}</h1>
          <p>{t('clientsDescription')}</p>
        </div>
        <div className="clients-categories">
          {['Finance', 'IT Consulting', 'Education', 'Game Development', 'Retail', 'Logistics', 'Telecom', 'Streaming', 'Development'].map((category, index) => (
            <span key={index} className="category-tag">{category}</span>
          ))}
        </div>
      </div>

      <div className="logos">
        <div className="logos-slide">
        <img src={LOGO3} alt="Client 1" className='Logo3'/>
          <img src={LOGO4} alt="Client 2" className='Logo4'/>
          <img src={LOGO5} alt="Client 3" className='Logo5'/>
          <img src={LOGO6} alt="Client 4" className='Logo6'/>
          <img src={LOGO4} alt="Client 5" className='Logo4'/>
          <img src={LOGO5} alt="Client 6" className='Logo5'/>
        </div>
      </div>
    </div>
  );
};

export default Clients;

const WhyUs_Data_Lv = [
    {
      title: 'Proaktīva pieeja',
      description:
        'Problēmas tiek ne tikai risinātas, bet arī paredzētas. Mūsu komanda pastāvīgi uzrauga jūsu datubāzes, lai laikus novērstu traucējumus. Regulāra pārskatīšana un optimizācija nodrošina nepārtrauktu darbību un palīdz novērst dīkstāvi nākotnē.',
      animation: 'animation2', // Same animation mappings
    },
    {
      title: 'Personalizēti risinājumi',
      description:
        'Katram uzņēmumam ir unikālas vajadzības, tāpēc mūsu pakalpojumi tiek pielāgoti jūsu mērķiem un izaicinājumiem. Vai nepieciešama datu migrācija, optimizācija vai drošības uzlabošana, piedāvājam risinājumus, kas atbilst jūsu biznesa attīstībai.',
      animation: 'animation3',
    },
    {
      title: 'Efektivitāte un uzticamība',
      description:
        'Mūsu mērķis ir izmaksu samazināšana un produktivitātes paaugstināšana, nodrošinot, ka jūsu datu infrastruktūra ir stabila un maksimāli optimizēta. Kamēr jūs fokusējaties uz biznesa attīstību, mēs pārvaldām jūsu datus, garantējot drošību un pieejamību visu diennakti.',
      animation: 'animation4',
    },
  ];
  
  export default WhyUs_Data_Lv;
  

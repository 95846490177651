import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Services from './components/Services/Services';
import Clients from './components/Clients/Clients';
import Contact from './components/Contact/Contact';
import Blog from './components/Blog/Blog';
import AllBlogsPage from './components/AllBlogsPage/AllBlogsPage'; // Blog List page
import BlogPostPage from './components/BlogPostPage/BlogPostPage'; // Individual Blog Post page
import ContactButton from './components/ContactButton/ContactButton'; // Import the floating contact button
import FAQ from './components/FAQs/FAQ';
import AdminPage from './components/pages/AdminPage';
import AdminLogin from './components/pages/AdminLogin';
import PrivateRoute from './components/PrivateRoute'; // Import the PrivateRoute component
import WhyUs from './components/WhyUs/WhyUs'

const App = () => {
  return (
    <Router>
      {/* Navbar is outside the Routes, so it appears on every page */}
      <Navbar />
      
      <Routes>
        {/* Main Page */}
        <Route
          path="/"
          element={
            <>
              <Hero />
              <About />
              <Services />
              <WhyUs/>
              <Blog /> {/* Blog Section on Main Page */}
              <Clients />
              <Contact />
            </>
          }
        />

	<Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<FAQ />} />

	<Route path="/blog" element={<AllBlogsPage />} />
        <Route path="/blog/:id" element={<BlogPostPage />} />

        <Route path="/faq" element={<FAQ />} />
        {/* Blog Pages */}
        <Route path="/blog" element={<AllBlogsPage />} />
        <Route path="/blog/:id" element={<BlogPostPage />} />

        {/* Admin Routes */}
        <Route path="/l1018jsu949" element={<AdminLogin />} />

        {/* Protect the admin page using PrivateRoute */}
        <Route
          path="/a1093jfikr8"
          element={
            <PrivateRoute>
              <AdminPage />
            </PrivateRoute>
          }
        />
      </Routes>

      {/* Footer is outside the Routes, so it appears on every page */}
      <Footer />

      {/* ContactButton should be placed here to appear on every page */}
      <ContactButton />
    </Router>
  );
};

export default App;

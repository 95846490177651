const WhyUs_Data_En = [
    {
      title: 'Proactive Approach',
      description:
        'Problems are not only solved but also anticipated. Our team continuously monitors your databases to prevent disruptions in advance. Regular reviews and optimization ensure continuous operation and help avoid downtime in the future.',
      animation: 'animation2', // Use identifiers to map to your Lottie animations
    },
    {
      title: 'Personalized Solutions',
      description:
        'Every company has unique needs, that is why our services are tailored to your goals and challenges. Whether you need data migration, optimization, or security enhancement, we offer solutions that align with your business development.',
      animation: 'animation3',
    },
    {
      title: 'Efficiency and reliability',
      description:
        'Our goal is to reduce costs and increase productivity by ensuring that your data infrastructure is stable and fully optimized. While you focus on business development, we manage your data, guaranteeing security and availability around the clock.',
      animation: 'animation4',
    },
  ];
  
  export default WhyUs_Data_En;
  

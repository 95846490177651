import React, { useEffect } from 'react';
import './About.css';
import MySQL from '../../assets/mysql.svg';
import ClickHouse from '../../assets/clickhouselogo.png';
import MariaDB from '../../assets/mariadb.svg';
import MicrosoftSQL from '../../assets/microsoftsql.svg';
import PostgreSQL from '../../assets/postgresql.svg';
import Redis from '../../assets/redis.svg';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const logosContainer = document.querySelector('.logos1');
    const logoSlide = document.querySelector('.logos-slide1');

    if (logoSlide && logosContainer) {
      // Clone the logos-slide and append the cloned version for infinite sliding
      const clone = logoSlide.cloneNode(true);
      logosContainer.appendChild(clone);
    }
  }, []);

  return (
    <div id='about' className='about'>
      <div className="about-title">
        <h1>{t('aboutUs')}</h1>
      </div>
      <div className="about-sections">
          <div className="about-para">
            <p>{t('aboutUs1')}</p>
            <p>{t('aboutUs2')}</p>
          </div>

          {/* Single row of database logos */}
          <div className="logos1">
            <div className="logos-slide1">
              <img src={MySQL} alt='mysql' />
              <img src={ClickHouse} alt='clickhouse' />
              <img src={MariaDB} alt='mariadb' />
              <img src={MicrosoftSQL} alt='microsoftsql' />
              <img src={PostgreSQL} alt='postgresql' />
              <img src={Redis} alt='redis' />
            </div>
          </div>
        </div>
      </div>
  );
};

export default About;

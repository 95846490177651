import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SuccessMessage from '../SuccessMessage'; // Import the SuccessMessage component

const ContactModalForm = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [charCount, setCharCount] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false); // State to control the success message display
  const maxChars = 800;

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = t('Name is required');
    }
    if (!formData.email) {
      errors.email = t('Email is required');
    } else if (!validateEmail(formData.email)) {
      errors.email = t('Invalid email format');
    }
    if (!formData.message) {
      errors.message = t('Message is required');
    }
    return errors;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const json = JSON.stringify({
      ...formData,
      access_key: '824b8e53-fabb-4c46-b123-bb5a47f24113',
    });

    const res = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      setShowSuccess(true); // Show success message
      setTimeout(() => {
        setShowSuccess(false); // Hide after 3 seconds
      }, 3000);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors({ ...formErrors, [name]: '' });

    if (name === 'message') {
      setCharCount(value.length);
    }
  };

  return (
    <div className="contact-modal-form">
      <h1>{t('contactUs')}</h1>
      <form onSubmit={onSubmit}>
        <label htmlFor="name">{t('YourName')}</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder={t('EnterYourName')}
          required
        />
        {formErrors.name && <p className="error">{formErrors.name}</p>}

        <label htmlFor="email">{t('YourEmail')}</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder={t('EnterYourEmail')}
          required
        />
        {formErrors.email && <p className="error">{formErrors.email}</p>}

        <label htmlFor="message">{t('LeaveTheMessage')}</label>
        <textarea
          name="message"
          rows="6"
          value={formData.message}
          onChange={handleChange}
          maxLength={maxChars}
          placeholder={t('EnterYourMessage')}
          required
        ></textarea>
        <p className="char-counter">{`${charCount}/${maxChars} ${t('characters')}`}</p>
        {formErrors.message && <p className="error">{formErrors.message}</p>}

        <button type="submit">{t('Submit')}</button>
      </form>

      {/* Show SuccessMessage when form is submitted successfully */}
      {showSuccess && <SuccessMessage message={t('Message sent successfully!')} />}
    </div>
  );
};

export default ContactModalForm;

import React, { useState, useEffect } from 'react';
import './Jobs.css';
import { useTranslation } from 'react-i18next';
import Jobs_Data from '../../assets/jobs_data';
import Jobs_Data_Lv from '../../assets/jobs_data_lv';
import Jobs_Full_Data from '../../assets/jobs_full_data';
import Jobs_Full_Data_Lv from '../../assets/jobs_full_data_lv';
import SuccessMessage from '../SuccessMessage'; // Import SuccessMessage

const Jobs = ({ jobsOpen, onClose }) => {
  const [jobsData, setJobsData] = useState([]);
  const [jobsFullData, setJobsFullData] = useState([]);
  const { i18n, t } = useTranslation();
  const [selectedJob, setSelectedJob] = useState(null);
  const [isDescriptionPanelOpen, setIsDescriptionPanelOpen] = useState(false);
  const [contactOverlayOpen, setContactOverlayOpen] = useState(false);
  const [joinOverlayOpen, setJoinOverlayOpen] = useState(false); // State for Join Us overlay
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    jobTitle: '',
    motivation: '',
    linkedin: '',
    cv: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false); // State to show success message

  const openContactOverlay = () => {
    setContactOverlayOpen(true);
  };

  const openJoinOverlay = () => {
    setJoinOverlayOpen(true);
  };

  const closeJoinOverlay = () => {
    setJoinOverlayOpen(false);
  };

  const closeContactOverlay = () => {
    setContactOverlayOpen(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = t('Name is required');
    }
    if (!formData.email) {
      errors.email = t('Email is required');
    } else if (!validateEmail(formData.email)) {
      errors.email = t('Invalid email format');
    }
    if (!formData.phone) {
      errors.phone = t('Phone number is required');
    }
    if (!formData.motivation) {
      errors.motivation = t('Motivation is required');
    } else if (formData.motivation.length > 800) {
      errors.motivation = t('Motivation exceeds 800 characters');
    }
    return errors;
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, cv: e.target.files[0] });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const formPayload = new FormData();
    formPayload.append('name', formData.name);
    formPayload.append('email', formData.email);
    formPayload.append('phone', formData.phone);
    formPayload.append('jobTitle', formData.jobTitle);
    formPayload.append('motivation', formData.motivation);
    formPayload.append('linkedin', formData.linkedin);
    if (formData.cv) {
      formPayload.append('cv', formData.cv);
    }
    formPayload.append('access_key', '824b8e53-fabb-4c46-b123-bb5a47f24113'); // Your API key here

    const res = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formPayload,
    }).then((res) => res.json());

    if (res.success) {
      setShowSuccess(true); // Show success message
      setTimeout(() => {
        setShowSuccess(false); // Hide success message after 3 seconds
      }, 3000);
      closeJoinOverlay();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  useEffect(() => {
    if (i18n.language === 'lv') {
      setJobsData(Jobs_Data_Lv);
      setJobsFullData(Jobs_Full_Data_Lv);
    } else {
      setJobsData(Jobs_Data);
      setJobsFullData(Jobs_Full_Data);
    }
  }, [i18n.language]);

  const handleJobClick = (job) => {
    const jobDetails = jobsFullData.find((item) => item.j_no === job.j_no);
    setSelectedJob(jobDetails);
    setIsDescriptionPanelOpen(true); // Open description panel
  };

  const closeDescriptionPanel = () => {
    setIsDescriptionPanelOpen(false); // Close description panel
    setSelectedJob(null);
  };

  return (
    <>
      {!isDescriptionPanelOpen && (
        <div className={`jobs-container-slide-in ${jobsOpen ? 'show' : ''}`}>
          <h1>{t('JoinTeam')}</h1>
          <p>{t('PartOfOurTeam')}</p>
          <button className="close-btn-jobs" onClick={onClose}>X</button>

          <div className="jobs-list">
            {jobsData.length > 0 ? (
              jobsData.map((job, index) => (
                <div
                  key={index}
                  className="job-item"
                  onClick={() => handleJobClick(job)}
                >
                  <h3>{job.j_name}</h3>
                </div>
              ))
            ) : (
              <p className="no-jobs">{t('NoJobs')}</p> // Show this when no jobs are available
            )}
          </div>

          <button className='contact-us-btn' onClick={openContactOverlay}>
            {t('contactUs')}
          </button>
        </div>
      )}

      {isDescriptionPanelOpen && selectedJob && (
        <div className="job-details-overlay show">
          <button className="close-btn" onClick={closeDescriptionPanel}>X</button>
          <div className="job-details">
            <h2>{selectedJob.j_name}</h2>
            <p>{selectedJob.j_full_desc}</p>
            <button className="join-us-btn" onClick={openJoinOverlay}>
              {t('Join Us')}
            </button>
          </div>
        </div>
      )}

      {/* Contact Overlay */}
      {contactOverlayOpen && (
        <div className="contact-overlay">
          <button className="close-btn" onClick={closeContactOverlay}>X</button>
          <div className="contact-form">
            <h2>{t('contactUs')}</h2>
            <form onSubmit={onSubmit}>
              <label>{t('YourName')}</label>
              <input
                type="text"
                name="name"
                placeholder={t('EnterYourName')}
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              {formErrors.name && <p className="error">{formErrors.name}</p>}

              <label>{t('YourEmail')}</label>
              <input
                type="email"
                name="email"
                placeholder={t('EnterYourEmail')}
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {formErrors.email && <p className="error">{formErrors.email}</p>}

              <label>{t('LeaveTheMessage')}</label>
              <textarea
                name="message"
                rows="6"
                placeholder={t('EnterYourMessage')}
                value={formData.message}
                onChange={handleInputChange}
                required
                maxLength="800"
              />
              {formErrors.message && <p className="error">{formErrors.message}</p>}

              <button type="submit">{t('Submit')}</button>
            </form>
          </div>
        </div>
      )}

      {/* Join Us Overlay */}
      {joinOverlayOpen && (
        <div className="join-overlay">
          <button className="close-btn-join" onClick={closeJoinOverlay}>X</button>
          <div className="join-form">
            <h2>{t('Join Us')}</h2>
            <form onSubmit={onSubmit}>
              <label>{t('YourName')}</label>
              <input
                type="text"
                name="name"
                placeholder={t('EnterYourName')}
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              {formErrors.name && <p className="error">{formErrors.name}</p>}

              <label>{t('YourEmail')}</label>
              <input
                type="email"
                name="email"
                placeholder={t('EnterYourEmail')}
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {formErrors.email && <p className="error">{formErrors.email}</p>}

              <label>{t('Phone')}</label>
              <input
                type="tel"
                name="phone"
                placeholder={t('EnterYourPhone')}
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              {formErrors.phone && <p className="error">{formErrors.phone}</p>}

              <label>{t('JobTitle')}</label>
              <select
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleInputChange}
                required
              >
                {jobsData.map((job) => (
                  <option key={job.j_no} value={job.j_name}>
                    {job.j_name}
                  </option>
                ))}
              </select>

              <label>{t('Motivation')}</label>
              <textarea
                name="motivation"
                rows="6"
                placeholder={t('A few words of motivation')}
                value={formData.motivation}
                onChange={handleInputChange}
                required
                maxLength="800"
              />
              {formErrors.motivation && <p className="error">{formErrors.motivation}</p>}

              <label>{t('LinkedIn (Optional)')}</label>
              <input
                type="url"
                name="linkedin"
                placeholder={t('Enter your LinkedIn URL')}
                value={formData.linkedin}
                onChange={handleInputChange}
              />

              <label>{t('Upload CV')}</label>
              <input
                type="file"
                name="cv"
                onChange={handleFileChange}
              />

              <button type="submit">{t('Submit')}</button>
            </form>
          </div>
        </div>
      )}

      {/* Success message after submission */}
      {showSuccess && <SuccessMessage message={t('Message sent successfully!')} />}
    </>
  );
};

export default Jobs;

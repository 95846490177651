import React, { useState, useRef } from 'react';
import './Navbar.css';
import AGDATA from '../../assets/AGDATA_Logo3.png';
import menu_open from '../../assets/menu_open.svg';
import menu_close from '../../assets/menu_close.svg';
import ModalContact from '../Modals/ModalContact';
import Jobs from '../Jobs/Jobs';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [jobsOpen, setJobsOpen] = useState(false);
  const menuRef = useRef();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  
  const openMenu = () => {
    setMenuOpen(true);
    menuRef.current.classList.add('open');
  };

  const closeMenu = () => {
    setMenuOpen(false);
    menuRef.current.classList.remove('open');
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const toggleJobs = () => {
    setJobsOpen(!jobsOpen);
    closeMenu(); // Close menu after opening jobs
  };

  const openContactModal = () => {
    setContactModalOpen(true);
    closeMenu();
  };

  const closeContactModal = () => {
    setContactModalOpen(false);
  };
  const handleNavigationToHero = () => {
    if (location.pathname === '/') {
      // If already on the homepage, scroll to Hero section
      const heroSection = document.querySelector('#hero');
      if (heroSection) {
        heroSection.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error('Hero section not found.');
      }
    } else {
      // If on another page, navigate to homepage and scroll to Hero after the page has loaded
      navigate('/');
      setTimeout(() => {
        const heroSection = document.querySelector('#hero');
        if (heroSection) {
          heroSection.scrollIntoView({ behavior: 'smooth' });
        } else {
          console.error('Hero section not found after navigation.');
        }
      }, 500); // Wait for 500ms to ensure the homepage is loaded
    }
  };
  
  

  // Function to handle navigation back to homepage and scroll to the correct section
  const handleNavigationToSection = (sectionId) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        document.querySelector(sectionId).scrollIntoView({ behavior: 'smooth' });
      }, 500); // Wait for the homepage to load before scrolling
    }
  };

  return (
    <div className='navbar'>
       <img
        src={AGDATA}
        alt='AGDATA Logo'
        className='nav-logo'
        onClick={handleNavigationToHero} // Click to go to Hero section
        style={{ cursor: 'pointer' }} // Add pointer cursor to indicate clickability
      />
      <img
        src={menu_open}
        onClick={openMenu}
        alt='Open Menu'
        className='nav-mob-open'
        style={{ display: menuOpen ? 'none' : 'block' }}
      />
      <ul ref={menuRef} className="nav-menu">
        <img
          src={menu_close}
          onClick={closeMenu}
          alt="Close Menu"
          className="nav-mob-close"
          style={{ display: menuOpen ? 'block' : 'none' }}
        />
        
        {/* Use AnchorLink for sections when on homepage */}
        <li>
          {location.pathname === '/' ? (
            <AnchorLink className='anchor-link' offset={50} href='#about' onClick={closeMenu}>
              <p>{t('aboutUs')}</p>
            </AnchorLink>
          ) : (
            <p className='anchor-link' onClick={() => handleNavigationToSection('#about')}>
              {t('aboutUs')}
            </p>
          )}
        </li>
        <li>
          {location.pathname === '/' ? (
            <AnchorLink className='anchor-link' offset={50} href='#services' onClick={closeMenu}>
              <p>{t('services')}</p>
            </AnchorLink>
          ) : (
            <p className='anchor-link' onClick={() => handleNavigationToSection('#services')}>
              {t('services')}
            </p>
          )}
        </li>
        <li>
          {location.pathname === '/' ? (
            <AnchorLink className='anchor-link' offset={50} href='#clients' onClick={closeMenu}>
              <p>{t('clients')}</p>
            </AnchorLink>
          ) : (
            <p className='anchor-link' onClick={() => handleNavigationToSection('#clients')}>
              {t('clients')}
            </p>
          )}
        </li>
        <li>
          {location.pathname === '/' ? (
            <AnchorLink className='anchor-link' offset={50} href='#whyus' onClick={closeMenu}>
              <p>{t('whyus')}</p>
            </AnchorLink>
          ) : (
            <p className='anchor-link' onClick={() => handleNavigationToSection('#whyus')}>
              {t('whyus')}
            </p>
          )}
        </li>
        
        

        {/* Blog page and other Links */}
        <li>
          <Link to="/blog" className='anchor-link' onClick={closeMenu}>
            <p>{t('Blog')}</p>
          </Link>
        </li>

        <li onClick={toggleJobs}>
          <p>{t('jobs')}</p>
        </li>

        {/* Contact Us section */}
        <li>
          {location.pathname === '/' ? (
            <AnchorLink className='anchor-link' offset={50} href='#contact' onClick={closeMenu}>
              <p>{t('contacts')}</p>
            </AnchorLink>
          ) : (
            <p className='anchor-link' onClick={() => handleNavigationToSection('#contact')}>
              {t('contacts')}
            </p>
          )}
        </li>

        {/* Language Switcher */}
        <li>
          <div className="language-switcher">
            <button 
              className={i18n.language === 'lv' ? 'active' : ''} 
              onClick={() => changeLanguage('lv')}>
              LV
            </button>
            <button 
              className={i18n.language === 'en' ? 'active' : ''} 
              onClick={() => changeLanguage('en')}>
              EN
            </button>
          </div>
        </li>

        {/* Contact Us Modal Button */}
        <p className='get-in-touch' onClick={openContactModal}>
            {t('contactUs')}
        </p>
      </ul>

      {contactModalOpen && <ModalContact onClose={closeContactModal} />}
      <Jobs jobsOpen={jobsOpen} onClose={() => setJobsOpen(false)} />
    </div>
  );
};

export default Navbar;

import React, { useRef, useEffect, useState } from 'react';
import './FAQ.css';
import { useTranslation } from 'react-i18next';

const FAQItem = ({ question, answer, isOpen, onClick }) => {
    const contentRef = useRef(null);
    const [height, setHeight] = useState('0px'); // Initial height of the answer content
  
    useEffect(() => {
      // Update the height of the content when isOpen changes
      if (isOpen) {
        setHeight(`${contentRef.current.scrollHeight}px`); // Set height to scrollHeight when open
      } else {
        setHeight('0px'); // Reset height when closed
      }
    }, [isOpen]);
  
    return (
      <div className="faq-item">
        <div className={`faq-question ${isOpen ? 'open' : ''}`} onClick={onClick}>
          <span>{isOpen ? '-' : '+'}</span> {question}
        </div>
        <div
          className="faq-answer"
          ref={contentRef}
          style={{ maxHeight: height }} // Dynamically set the max-height
        >
          <div className="faq-answer-content">{answer}</div> {/* Inner wrapper for smooth animation */}
        </div>
      </div>
    );
  };

const FAQ = () => {
  const { t } = useTranslation(); // Use the translation hook
  const [openIndex, setOpenIndex] = useState(null); // Track the currently open FAQ index

  // Get the translated questions and answers
  const faqData = t('faq.questions', { returnObjects: true });

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle the clicked FAQ and close the others
  };

  return (
    <div className="faq-page">
      <h1>{t('faq.title')} <span className="faq-highlight">{t('faq.highlight')}</span></h1>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index} // Only the current openIndex is opened
            onClick={() => handleToggle(index)} // Click handler to toggle FAQs
          />
        ))}
      </div>
    </div>
  );
};

export default FAQ;

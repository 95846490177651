import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // Add useLocation
import './BlogPostPage.css';
import axios from 'axios';
import 'prismjs/themes/prism.css';
import Prism from 'prismjs';

const BlogPostPage = () => {
  const { id } = useParams();  // Get the blog id from the URL
  const navigate = useNavigate();
  const location = useLocation();  // Get the location object to access the state
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);  // Add a loading state
  const [error, setError] = useState(null);  // Add an error state

  useEffect(() => {
    // Fetch the blog post by id
    axios.get(`https://agdata.lv/api/blogs/${id}`)
      .then((response) => {
        setPost(response.data);  // Set the fetched blog post
        setLoading(false); // Stop the loading state
        Prism.highlightAll(); 
      })
      .catch((error) => {
        console.error('Error fetching blog post:', error);
        setError('Failed to load the blog post.');  // Set an error message
        setLoading(false);  // Stop the loading state
      });
  }, [id]);

  const goBackToAllBlogs = () => {
    const previousPage = location.state?.page || 1;  // Get the previous page from location.state
    navigate(`/blog?page=${previousPage}`);  // Navigate back to the correct page
  };

  if (loading) {
    return <p>Loading blog post...</p>;
  }

  if (error) {
    return <p>{error}</p>;  // Display the error message
  }

  if (!post) {
    return <p>No blog post found.</p>;
  }

  return (
    <div className="blog-post-page">
      <button className="back-btn" onClick={goBackToAllBlogs}>← Back to Blogs</button>
      <div className="blog-post-container">
        <h1 className="blog-post-title">{post.title}</h1>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: post.content }}  // Render the HTML content, including images
        />
      </div>
    </div>
  );
};

export default BlogPostPage;
